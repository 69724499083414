import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-xs" }
const _hoisted_2 = { class: "shadow-md bg-white px-5 py-5 rounded-lg" }
const _hoisted_3 = { class: "flex gap-10" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-sm font-semibold mt-2 text-center w-40 text-neutral-dark" }
const _hoisted_6 = { class: "text-center gap-1 w-full text-base mt-4" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-sm font-bold mt-2 text-center w-40 text-neutral-dark" }
const _hoisted_10 = { class: "text-center gap-1 w-full text-base mt-4" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "font-semibold text-xl border-t py-5 text-neutral-dark" }
const _hoisted_13 = { class: "flex gap-10 overflow-x-auto max-w-full" }
const _hoisted_14 = {
  key: 0,
  class: "flex gap-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Form"], {
      as: "el-form",
      "validation-schema": $setup.schema,
      "initial-values": $setup.formData,
      "label-position": "top"
    }, {
      default: _withCtx(({ isSubmitting, setFieldValue, setFieldError }) => [
        _createElementVNode("div", {
          class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Company Documents ", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode($setup["Field"], { name: "CompanyRegistrationDocument" }, {
                  default: _withCtx(({ value, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      error: errorMessage,
                      modelValue: $setup.formData.CompanyRegistrationDocument,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formData.CompanyRegistrationDocument) = $event)),
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          (
                        $setup.size($setup.formData.CompanyRegistrationDocument) === 0 ||
                        !$setup.formData.CompanyRegistrationDocument
                      )
                            ? (_openBlock(), _createBlock(_component_el_upload, {
                                key: 0,
                                class: "custom-upload",
                                "auto-upload": false,
                                "file-list": value ? value : [],
                                drag: "",
                                multiple: "",
                                limit: 2,
                                "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'CompanyRegistrationDocument',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                file: "",
                                disabled: $setup.size(value as any) > 1 || $props.tenantId !== null,
                                "on-preview": $setup.handlePreview,
                                action: "",
                                accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["BaseSvgIcon"], {
                                      class: "w-20 h-20 text-neutral-dark",
                                      name: "UploadFile"
                                    })
                                  ]),
                                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                                ]),
                                _: 2
                              }, 1032, ["file-list", "on-change", "disabled"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode($setup["FileDisplay"], {
                                  file: $setup.formData.CompanyRegistrationDocument[0],
                                  "is-edit": $props.tenantId === null,
                                  onUpdateFile: 
                            (file) =>
                              $setup.handleUpdateFile(
                                file,
                                'CompanyRegistrationDocument',
                                setFieldValue
                              )
                          
                                }, null, 8, ["file", "is-edit", "onUpdateFile"])
                              ])),
                          _createElementVNode("div", _hoisted_5, [
                            _createTextVNode(_toDisplayString($setup.SYSTEM.company_registration_document) + " ", 1),
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("button", {
                                class: "underline text-file font-bold hover:text-blue-500",
                                disabled: $setup.isDisableDownLoadTemplate,
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                            $setup.handleDownloadTemplate({
                              TemplateType:
                                $setup.SERVICE_TYPE.TemplateCompanyRegistration,
                            })
                          ), ["prevent"]))
                              }, " Download Template ", 8, _hoisted_7)
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["error", "modelValue"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode($setup["Field"], { name: "LetterOfAuthorization" }, {
                  default: _withCtx(({ value, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      error: errorMessage,
                      "model-value": $setup.formData.LetterOfAuthorization,
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          (
                        $setup.size($setup.formData.LetterOfAuthorization) === 0 ||
                        !$setup.formData.LetterOfAuthorization
                      )
                            ? (_openBlock(), _createBlock(_component_el_upload, {
                                key: 0,
                                class: "custom-upload",
                                "auto-upload": false,
                                "file-list": value ? value : [],
                                drag: "",
                                multiple: "",
                                limit: 2,
                                "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'LetterOfAuthorization',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                file: "",
                                disabled: $setup.size(value as any) > 1 || $props.tenantId !==null,
                                "on-preview": $setup.handlePreview,
                                action: "",
                                accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["BaseSvgIcon"], {
                                      class: "w-20 h-20 text-neutral-dark",
                                      name: "UploadFile"
                                    })
                                  ]),
                                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                                ]),
                                _: 2
                              }, 1032, ["file-list", "on-change", "disabled"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode($setup["FileDisplay"], {
                                  file: $setup.formData.LetterOfAuthorization[0],
                                  "is-edit": $props.tenantId === null,
                                  onUpdateFile: 
                            (fileList) =>
                              $setup.handleUpdateFile(
                                fileList,
                                'LetterOfAuthorization',
                                setFieldValue
                              )
                          
                                }, null, 8, ["file", "is-edit", "onUpdateFile"])
                              ])),
                          _createElementVNode("div", _hoisted_9, [
                            _createTextVNode(_toDisplayString($setup.SYSTEM.letter_of_authorization) + " ", 1),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("button", {
                                class: "underline text-file font-bold hover:text-blue-500",
                                disabled: $setup.isDisableDownLoadTemplate,
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
                            $setup.handleDownloadTemplate({
                              TemplateType:
                                $setup.SERVICE_TYPE.TemplateLetterOfAuthorization,
                            })
                          ), ["prevent"]))
                              }, " Download Template ", 8, _hoisted_11)
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["error", "model-value"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("p", _hoisted_12, _toDisplayString($setup.SYSTEM.other_relevant_documents), 1),
              _createVNode($setup["Field"], { name: "OtherRelevantDocuments" }, {
                default: _withCtx(({ value, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    error: errorMessage,
                    "model-value": $setup.formData.OtherRelevantDocuments
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        ($setup.files.length > 0)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.files, (file, index) => {
                                return (_openBlock(), _createElementBlock("li", { key: index }, [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["FileDisplay"], {
                                      file: file,
                                      "is-edit": $props.tenantId === null,
                                      onUpdateFile: 
                            (fileList) =>
                              $setup.handleUpdateFile(
                                fileList,
                                'OtherRelevantDocuments',
                                setFieldValue,
                                index
                              )
                          
                                    }, null, 8, ["file", "is-edit", "onUpdateFile"])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (
                      $setup.size($setup.formData.OtherRelevantDocuments) < 3 &&
                      $setup.files.length < 3
                    )
                          ? (_openBlock(), _createBlock(_component_el_upload, {
                              key: 1,
                              class: _normalizeClass(["custom-upload", [
                    $setup.size(value as any) > 2 ? 'upload-disable' : 'custom-upload-file',
                  ]]),
                              "auto-upload": false,
                              "file-list": $setup.files,
                              drag: "",
                              multiple: "",
                              limit: 3,
                              "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'OtherRelevantDocuments',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                              file: "",
                              disabled: $setup.size(value as any) > 3 || $props.tenantId !== null,
                              "on-preview": $setup.handlePreview,
                              accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                              "show-file-list": false,
                              action: ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createVNode($setup["BaseSvgIcon"], {
                                    class: "w-20 h-20 text-neutral-dark",
                                    name: "UploadFile"
                                  })
                                ]),
                                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                              ]),
                              _: 2
                            }, 1032, ["class", "file-list", "on-change", "disabled"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["error", "model-value"])
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"])
  ]))
}