import { defineComponent as _defineComponent } from 'vue'
import { ICompanyInformation } from "@/models/Tenant";
import { configure, Field, Form } from "vee-validate";
import { computed, ComputedRef, onMounted, ref, watch } from "vue";
import MetadataModule from "@/store/modules/Metadata";
import { object, string } from "yup";
import { ICountries, IPhoneCode, IProvinces } from "@/models/Metadata";
import { MAX_CHARACTER } from "@ems/constants/validation_form";


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyInformation.Form',
  props: {
  isReadOnly: {
    type: Boolean,
    default: false,
  },
  initialData: {
    type: Object as () => ICompanyInformation,
  },
},
  emits: ["update:companyData", "update:isCompanyInfoValid"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;
const props = __props;

const formData = ref<ICompanyInformation>(
  props.initialData ?? {
    Country: "",
    Province: "",
    CompanyRegistrationNumber: "",
    CompanyName: "",
    Address: "",
    PhoneCode: "",
    ContactNumber: "",
  }
);

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const allCountries: ComputedRef<ICountries[]> = computed(
  () => MetadataModule.dataCountriesNewGetter
);

const showProvince = ref(false);
const optionsCountry = ref<ICountries[]>([]);
const optionsProvince = ref<IProvinces[]>([]);
const phoneCode = ref<IPhoneCode[]>([]);
const optionsPhoneCode = ref<IPhoneCode[]>([]);

const schema = object({
  CompanyRegistrationNumber: string()
    .label("Company Registration Number")
    .max(20, "Company Registration Number must be at most 20 characters"),
  CompanyName: string()
    .required()
    .label("Company Name")
    .max(150, MAX_CHARACTER.COMPANY_NAME),
  Address: string().nullable().label("Address").max(255, MAX_CHARACTER.ADDRESS),
  ContactNumber: string()
    .nullable()
    .label("Contact Number")
    .test(
      "max-length",
      "Contact Number must be at most 15 characters",
      (value) => !value || value.length <= 15
    )
    .test(
      "is-numeric",
      "Contact Number must be numeric (allowed: 0-9)",
      (value) => !value || /^\d+$/.test(value)
    ),
});

const handleCountryChange = async (
  value: string,
  setFieldValue: any,
  setFieldError: any
) => {
  setFieldValue("Country", value);
  // Logic to determine if Province field should be shown
  await MetadataModule.getAllProvincesByCountryCodeAction(value);

  optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
  showProvince.value = optionsProvince.value.length > 0;

  const countryEntry = Object.entries(allCountries.value).find(
    ([key, item]) => item.Value === value
  );
  const country: ICountries | undefined = countryEntry
    ? countryEntry[1]
    : undefined;
  phoneCode.value =
    country?.PhoneCode?.split(",").map((code) => ({
      Value: code.trim(),
      Name: `${code.trim()} (${country.Name})`,
    })) ?? [];
  formData.value.PhoneCode = phoneCode.value[0].Value;

  filterMethod("");
};

const handleCountryBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = optionsCountry.value.some((item) => item.Value === value);
  if (!validValue) {
    setFieldValue("Country", "");
  }
};

const handleProvinceBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = optionsProvince.value.some((item) => item.Value === value);
  if (!validValue) {
    setFieldValue("Province", "");
  }
};

const handlePhoneCodeBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = phoneCode.value.some((item) => item === value);
  if (!validValue) {
    setFieldValue("PhoneCode", "");
  }
};

const resetCountryOptions = () => {
  optionsCountry.value = Object.entries(allCountries.value).map(
    ([key, item]) => ({
      Value: item.Value,
      Name: item.Name,
      PhoneCode: item.PhoneCode,
    })
  );
};

const resetProvinceOptions = () => {
  optionsProvince.value = [...MetadataModule.dataProvincesByCountryCodeGetter];
};

const resetPhoneCodeOptions = () => {
  phoneCode.value = [...optionsPhoneCode.value];
};

const filterCountryOptions = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    resetCountryOptions();
  }
};

const filterProvinceOptions = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsProvince.value =
        MetadataModule.dataProvincesByCountryCodeGetter.filter((item) =>
          item.Name.toLowerCase().includes(query.toLowerCase())
        );
    }, 200);
  } else {
    resetProvinceOptions();
  }
};

const filterMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    optionsCountry.value = Object.entries(allCountries.value).map(
      ([key, item]) => ({
        Value: item.Value,
        Name: item.Name,
        PhoneCode: item.PhoneCode,
      })
    );
  }
};

const normalizeString = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const filterMethodValueName = (query: string) => {
  if (query) {
    setTimeout(() => {
      const normalizedQuery = normalizeString(query);
      optionsProvince.value =
        MetadataModule.dataProvincesByCountryCodeGetter.filter((item) => {
          return normalizeString(item.Name).includes(normalizedQuery);
        });
    }, 200);
  } else {
    optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
  }
};

const filterPhoneCode = (query: string) => {
  if (query) {
    setTimeout(() => {
      phoneCode.value = optionsPhoneCode.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else {
    phoneCode.value = [...optionsPhoneCode.value];
  }
};

const filterOptionCountry = () => {
  optionsCountry.value = Object.entries(allCountries.value)
    .map(([key, item]) => {
      return {
        Value: `${item.Value}`,
        Name: `${item.Name}`,
        PhoneCode: `${item.PhoneCode}`,
      };
    })
    .slice()
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

watch(allCountries, () => {
  filterOptionCountry();
  optionsPhoneCode.value = optionsCountry.value.flatMap(
    (country: ICountries) => {
      if (!country.PhoneCode) return [];
      return country.PhoneCode.split(",").map((code) => ({
        Value: `${code.trim()}|${country.Name}`,
        Name: `${code.trim()} (${country.Name})`,
      }));
    }
  );
});

onMounted(async () => {
  MetadataModule.getAllCountryAction();
  filterOptionCountry();
});

const sortedOptions = () => {
  return optionsCountry.value
    .slice()
    .sort((a, b) => a.Name.localeCompare(b.Name));
};
const sortedPhoneCode = () => {
  return phoneCode.value.slice().sort((a, b) => a.Name.localeCompare(b.Name));
};

watch(
  formData,
  (newValue) => {
    emit("update:isCompanyInfoValid", schema.isValidSync(newValue));
    if (newValue.PhoneCode && newValue.PhoneCode.length > 0) {
      newValue.PhoneCode = newValue.PhoneCode.split("|")[0];
    }
    emit("update:companyData", newValue);
  },
  { deep: true }
);

watch(
  () => props.initialData,
  (newData) => {
    if (newData) {
      formData.value = { ...formData.value, ...newData };
    }
  },
  { immediate: true }
);

const __returned__ = { emit, props, formData, allCountries, showProvince, optionsCountry, optionsProvince, phoneCode, optionsPhoneCode, schema, handleCountryChange, handleCountryBlur, handleProvinceBlur, handlePhoneCodeBlur, resetCountryOptions, resetProvinceOptions, resetPhoneCodeOptions, filterCountryOptions, filterProvinceOptions, filterMethod, normalizeString, filterMethodValueName, filterPhoneCode, filterOptionCountry, sortedOptions, sortedPhoneCode, get ICompanyInformation() { return ICompanyInformation }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, ComputedRef, onMounted, ref, watch, get MetadataModule() { return MetadataModule }, get object() { return object }, get string() { return string }, get ICountries() { return ICountries }, get IPhoneCode() { return IPhoneCode }, get IProvinces() { return IProvinces }, get MAX_CHARACTER() { return MAX_CHARACTER } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})