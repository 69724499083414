import { defineComponent as _defineComponent } from 'vue'
import { IBuyerInformation } from "@/models/Tenant";
import { configure, Field, Form } from "vee-validate";
import { computed, ComputedRef, onMounted, ref, watch } from "vue";
import MetadataModule from "@/store/modules/Metadata";
import { object, string } from "yup";
import {
  MAX_CHARACTER,
  NUMERIC_VALIDATION,
} from "@ems/constants/validation_form";
import { ICountries, IPhoneCode } from "@/models/Metadata";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuyerInformation.Form',
  props: {
  isReadOnly: {
    type: Boolean,
    default: false,
  },
  initialData: {
    type: Object as () => IBuyerInformation,
  },
},
  emits: ["update:buyerData", "update:isBuyerInfoValid"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;
const props = __props;

const optionsPhoneCode = ref<IPhoneCode[]>([]);
const phoneCode = ref<IPhoneCode[]>([]);
const optionsCountry = ref<ICountries[]>([]);
const allCountries: ComputedRef<ICountries[]> = computed(
  () => MetadataModule.dataCountriesNewGetter
);

const formData = ref<IBuyerInformation>(
  props.initialData ?? {
    PersonInCharge: "",
    Designation: "",
    BillingAddress: "",
    PhoneCode: "",
    BuyerContactNumber: "",
    BuyerEmail: "",
    AlternativeEmail: "",
  }
);

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const schema = object({
  PersonInCharge: string()
    .label("Person-in-charge")
    .max(255, MAX_CHARACTER.PERSON_IN_CHARGE),
  Designation: string()
    .label("Designation")
    .max(150, MAX_CHARACTER.DESIGNATION),
  BillingAddress: string()
    .label("Billing Address")
    .max(255, MAX_CHARACTER.BUYER_ADDRESS),
  BuyerContactNumber: string()
    .label("Buyer Contact Number")
    .test(
      "max-length",
      "Buyer Contact Number must be at most 15 characters",
      (value) => !value || value.length <= 15
    )
    .test(
      "is-numeric",
      "Buyer Contact Number must be numeric (allowed: 0-9)",
      (value) => !value || /^\d+$/.test(value)
    ),
  BuyerEmail: string()
    .label("Buyer Email")
    .email("Invalid email format.")
    .max(150, MAX_CHARACTER.BUYER_EMAIL),
  AlternativeEmail: string()
    .label("Alternative Email")
    .email("Invalid email format.")
    .max(150, MAX_CHARACTER.ALTERNATIVE_EMAIL),
});

const handlePhoneCodeBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = phoneCode.value.some((item) => item === value);
  if (!validValue) {
    setFieldValue("PhoneCode", "");
  }
};

const filterPhoneCode = (query: string) => {
  if (query) {
    setTimeout(() => {
      phoneCode.value = optionsPhoneCode.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else {
    phoneCode.value = [...optionsPhoneCode.value];
  }
};

const resetPhoneCodeOptions = () => {
  phoneCode.value = [...optionsPhoneCode.value];
};

const filterOptionCountry = () => {
  optionsCountry.value = Object.entries(allCountries.value)
    .map(([key, item]) => {
      return {
        Value: `${item.Value}`,
        Name: `${item.Name}`,
        PhoneCode: `${item.PhoneCode}`,
      };
    })
    .slice()
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

const sortedPhoneCode = () => {
  return phoneCode.value.slice().sort((a, b) => a.Name.localeCompare(b.Name));
};

watch(allCountries, () => {
  filterOptionCountry();
  optionsPhoneCode.value = [
    ...new Map(
      optionsCountry.value
        .flatMap((country: ICountries) => {
          if (!country.PhoneCode) return [];
          return country.PhoneCode.split(",").map((code) => ({
            Value: `${code.trim()}|${country.Name}`,
            Name: `${code.trim()} (${country.Name})`,
          }));
        })
        .map((item) => [item.Value, item])
    ).values(),
  ];
});

onMounted(async () => {
  filterOptionCountry();
  optionsPhoneCode.value = [
    ...new Map(
      optionsCountry.value
        .flatMap((country: ICountries) => {
          if (!country.PhoneCode) return [];
          return country.PhoneCode.split(",").map((code) => ({
            Value: `${code.trim()}|${country.Name}`,
            Name: `${code.trim()} (${country.Name})`,
          }));
        })
        .map((item) => [item.Value, item])
    ).values(),
  ];
});

watch(
  formData,
  (newValue) => {
    emit("update:isBuyerInfoValid", schema.isValidSync(newValue));
    if (newValue.PhoneCode && newValue.PhoneCode.length > 0) {
      newValue.PhoneCode = newValue.PhoneCode.split("|")[0];
    }
    emit("update:buyerData", newValue);
  },
  { deep: true }
);

watch(
  () => props.initialData,
  (newData) => {
    if (newData) {
      formData.value = { ...formData.value, ...newData };
    }
  },
  { immediate: true }
);

const __returned__ = { emit, props, optionsPhoneCode, phoneCode, optionsCountry, allCountries, formData, schema, handlePhoneCodeBlur, filterPhoneCode, resetPhoneCodeOptions, filterOptionCountry, sortedPhoneCode, get IBuyerInformation() { return IBuyerInformation }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, ComputedRef, onMounted, ref, watch, get MetadataModule() { return MetadataModule }, get object() { return object }, get string() { return string }, get MAX_CHARACTER() { return MAX_CHARACTER }, get NUMERIC_VALIDATION() { return NUMERIC_VALIDATION }, get ICountries() { return ICountries }, get IPhoneCode() { return IPhoneCode } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})