import { defineComponent as _defineComponent } from 'vue'
import { IFileMapObject } from "@/models/Company";
import { IDocumentsInformation } from "@/models/Tenant";
import { DOCTYPE, SERVICE_TYPE } from "@ems/constants/doc_type";
import { UPLOAD_FILE_VALIDATION } from "@ems/constants/validation_form";
import { SYSTEM } from "@ems/locales/system";
import { ElMessage } from "element-plus";
import { size } from "lodash";
import { configure, Field, Form } from "vee-validate";
import { onMounted, ref, watch } from "vue";
import { object, string } from "yup";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import FileDisplay from "@/components/Common/FileHelper/FileDisplay.vue";
import { downloadResource } from "@/utils/helpers/downloadDocument";
import TenantUserModule from "../TenantUser.module";
import { IGetProfileTemplate } from "@/models/Common";
import RequiredDocuments from "@/components/Common/FileHelper/RequiredDocuments.vue";
import CompanyAccountManagementModule from "@ems/containers/CompanyAccountManagement/CompanyAccountManagement.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsInformation.Form',
  props: {
  tenantId: {
    type: String,
    default: null,
  },
},
  emits: ["update:documentData"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;
const props = __props;

const files = ref<IFileMapObject[]>([]);
const isDisableDownLoadTemplate = ref<boolean>(false);

const formData = ref<IDocumentsInformation>({
  CompanyRegistrationDocument: "",
  LetterOfAuthorization: "",
  OtherRelevantDocuments: "",
});

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const NUMBER_FORM = {
  MAX_FILES: 1,
};

const schema = object({
  ClientType: string().required().label("ClientType"),
  Email: string()
    .required()
    .email("Contact Email must be a valid email (abc@abc.abc)")
    .label("Contact Email")
    .max(150, "Contact Email must be at most 150 characters"),
});

const handleDownloadTemplate = async (serviceType: IGetProfileTemplate) => {
  isDisableDownLoadTemplate.value = true;
  await TenantUserModule.downloadProfileTemplate(serviceType);
  const downloadContent = TenantUserModule.dataDownloadTemplateGetter;
  if (downloadContent) {
    downloadResource(downloadContent.FileContent, downloadContent.FileName);
  }
  isDisableDownLoadTemplate.value = false;
};

const onChangeFile = (
  name: keyof IDocumentsInformation,
  showFileList: any,
  setFieldValue: any,
  setFieldError: any
) => {
  // Get Newest File
  const newFile = showFileList[showFileList.length - 1];

  // Check File validation
  const isAllowedType =
    /\.(pdf|doc|docx|jpg|png|jpeg|xls|xlsx|ppt|pptx)$/i.test(newFile.name);
  const isLessThan25MB = newFile.size / 1024 / 1024 < 25;

  if (!isAllowedType || !isLessThan25MB) {
    showFileList.pop();

    const errorMessage = !isAllowedType
      ? UPLOAD_FILE_VALIDATION.TYPE
      : UPLOAD_FILE_VALIDATION.SIZE;

    if (showFileList.length === 0) {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    } else {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    }

    setFieldValue(name, showFileList);
    formData.value[name] = showFileList;
    return;
  }

  // Replace File logic
  if (
    showFileList.length > NUMBER_FORM.MAX_FILES &&
    name != DOCTYPE[DOCTYPE.OtherRelevantDocuments]
  ) {
    showFileList.shift();
  } else {
    setFieldError(name, null);
  }
  if (name === DOCTYPE[DOCTYPE.OtherRelevantDocuments]) {
    files.value.push(newFile);
  }
  setFieldValue(name, showFileList);
  formData.value[name] = showFileList;
};

const handleUpdateFile = (
  file: any,
  name: keyof IDocumentsInformation,
  setFieldValue: any,
  index?: number
) => {
  if (name === DOCTYPE[DOCTYPE.OtherRelevantDocuments]) {
    if (index !== undefined && index >= 0 && index < files.value.length) {
      const updatedFiles = [...files.value];

      updatedFiles[index] = file;

      files.value = updatedFiles;
      setFieldValue(name, files.value);
      formData.value[name] = files.value;
    }
    return;
  }
  setFieldValue(name, [file]);
  formData.value[name] = [file];
};

const handlePreview = (uploadFile: any) => {
  window.open(URL.createObjectURL(uploadFile.raw));
};

watch(
  formData,
  (newValue) => {
    emit("update:documentData", newValue);
  },
  { deep: true }
);

watch(
  () => props.tenantId,
  async (newTenantId) => {
    if (!newTenantId) return;
    await CompanyAccountManagementModule.getFiles({
      TenantId: newTenantId,
    });

    CompanyAccountManagementModule.getDataFilesGetter.map((file: any) => {
      const mappedFile = {
        name: file.Name,
        size: new Blob([file.Content]).size,
        status: "ready",
        raw: new Blob([file.Content], { type: file.Type }),
        uid: file.Id,
        url: file.Content,
      };
      switch (file.DocType) {
        case DOCTYPE[DOCTYPE.CompanyRegistrationDocument]:
          formData.value.CompanyRegistrationDocument = [mappedFile];
          break;
        case DOCTYPE[DOCTYPE.LetterOfAuthorization]:
          formData.value.LetterOfAuthorization = [mappedFile];
          break;
        case DOCTYPE[DOCTYPE.OtherRelevantDocuments]:
          files.value.push(mappedFile);
          break;
      }
    });
  },
  { deep: true }
);

const __returned__ = { emit, props, files, isDisableDownLoadTemplate, formData, NUMBER_FORM, schema, handleDownloadTemplate, onChangeFile, handleUpdateFile, handlePreview, get IFileMapObject() { return IFileMapObject }, get IDocumentsInformation() { return IDocumentsInformation }, get DOCTYPE() { return DOCTYPE }, get SERVICE_TYPE() { return SERVICE_TYPE }, get UPLOAD_FILE_VALIDATION() { return UPLOAD_FILE_VALIDATION }, get SYSTEM() { return SYSTEM }, get ElMessage() { return ElMessage }, get size() { return size }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, onMounted, ref, watch, get object() { return object }, get string() { return string }, BaseSvgIcon, FileDisplay, get downloadResource() { return downloadResource }, get TenantUserModule() { return TenantUserModule }, get IGetProfileTemplate() { return IGetProfileTemplate }, RequiredDocuments, get CompanyAccountManagementModule() { return CompanyAccountManagementModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})