import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xs" }
const _hoisted_2 = { class: "shadow-md bg-white px-5 py-5 rounded-lg flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    "initial-values": $props.initialData,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting, setFieldError }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Account Information ", -1)),
            _createVNode($setup["Field"], { name: "ClientType" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "w-full label-custom",
                  error: errorMessage,
                  label: "Client Type",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps(field, {
                      modelValue: $setup.formData.ClientType,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData.ClientType) = $event)),
                      class: "w-full custom-select",
                      placeholder: !$props.isReadOnly ? 'Select Client Type' : '',
                      size: "large",
                      disabled: $props.isReadOnly
                    }), {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "Upstream Partner",
                          value: $setup.RESUITE_ROLE.UpstreamPartner
                        }, null, 8, ["value"]),
                        _createVNode(_component_el_option, {
                          label: "Supply Chain Partner",
                          value: $setup.RESUITE_ROLE.SupplyChainPartner
                        }, null, 8, ["value"])
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "Email" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: 
                errorMessage
                  ? errorMessage
                  : $setup.emailCache.has($setup.formData.Email)
                  ? $setup.emailCache.get($setup.formData.Email)?.errorMessage
                  : ''
              ,
                  label: "Account Email",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps(field, {
                      modelValue: $setup.formData.Email,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formData.Email) = $event)),
                      placeholder: !$props.isReadOnly ? 'Enter Account Email' : '',
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly,
                      required: "",
                      onBlur: ($event: any) => ($setup.debouncedCheckDuplicateEmail(setFieldError))
                    }), null, 16, ["modelValue", "placeholder", "disabled", "onBlur"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 2
            }, 1024)
          ])
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}