import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-xs" }
const _hoisted_2 = { class: "shadow-md bg-white px-5 py-5 rounded-lg flex flex-col mb-5" }
const _hoisted_3 = { class: "grid grid-cols-8 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    "initial-values": $props.initialData,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting, setFieldValue }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Buyer Information ", -1)),
            _createVNode($setup["Field"], { name: "PersonInCharge" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Person-in-charge (Buyer)"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Person In Charge' : ''
                    }, field, {
                      modelValue: $setup.formData.PersonInCharge,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData.PersonInCharge) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "Designation" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Designation"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Designation' : ''
                    }, field, {
                      modelValue: $setup.formData.Designation,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formData.Designation) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "BillingAddress" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Billing / Mailing Address"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Billing Address' : ''
                    }, field, {
                      modelValue: $setup.formData.BillingAddress,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formData.BillingAddress) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["Field"], { name: "PhoneCode" }, {
                default: _withCtx(({ field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom col-span-2",
                    error: errorMessage,
                    label: "Phone Code"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps(field, {
                        modelValue: $setup.formData.PhoneCode,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formData.PhoneCode) = $event)),
                        class: "w-full custom-select",
                        placeholder: !$props.isReadOnly ? 'Select Phone Code' : '',
                        filterable: "",
                        onInput: _cache[4] || (_cache[4] = (value) => $setup.filterPhoneCode(value.target?.value)),
                        onChange: _cache[5] || (_cache[5] = ($event: any) => ($setup.filterPhoneCode(''))),
                        onBlur: (event) => $setup.handlePhoneCodeBlur(event, setFieldValue),
                        onFocus: $setup.resetPhoneCodeOptions,
                        disabled: $props.isReadOnly
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedPhoneCode(), (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["modelValue", "placeholder", "onBlur", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 2
              }, 1024),
              _createVNode($setup["Field"], { name: "BuyerContactNumber" }, {
                default: _withCtx(({ field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom col-span-6",
                    error: errorMessage,
                    label: "Buyer Contact Number"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        placeholder: !$props.isReadOnly ? 'Enter Buyer Contact Number' : ''
                      }, field, {
                        modelValue: $setup.formData.BuyerContactNumber,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formData.BuyerContactNumber) = $event)),
                        class: "custom-placeholder",
                        disabled: $props.isReadOnly
                      }), null, 16, ["placeholder", "modelValue", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ]),
            _createVNode($setup["Field"], { name: "BuyerEmail" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Buyer Email Address"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Buyer Email Address' : ''
                    }, field, {
                      modelValue: $setup.formData.BuyerEmail,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.formData.BuyerEmail) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "AlternativeEmail" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Alternative Email Address"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: 
                  !$props.isReadOnly ? 'Enter Alternative Email Address' : ''
                
                    }, field, {
                      modelValue: $setup.formData.AlternativeEmail,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.formData.AlternativeEmail) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ])
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}