import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-xs" }
const _hoisted_2 = { class: "shadow-md bg-white px-5 py-5 rounded-lg flex flex-col my-5" }
const _hoisted_3 = { class: "grid grid-cols-8 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    "initial-values": $props.initialData,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting, setFieldError, setFieldValue }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[12] || (_cache[12] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Company Information ", -1)),
            _createElementVNode("div", {
              class: _normalizeClass([
              'fields-container',
              { 'single-field': !$setup.showProvince && !$props.initialData?.Province },
            ])
            }, [
              _createVNode($setup["Field"], { name: "Country" }, {
                default: _withCtx(({ field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "w-full label-custom",
                    error: errorMessage,
                    label: "Country",
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps(field, {
                        modelValue: $setup.formData.Country,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData.Country) = $event)),
                        "value-key": "id",
                        class: "w-full custom-select",
                        placeholder: !$props.isReadOnly ? 'Select Country' : '',
                        filterable: "",
                        onInput: _cache[1] || (_cache[1] = (value) => $setup.filterCountryOptions(value.target?.value)),
                        onChange: (value) => $setup.handleCountryChange(value, setFieldValue, setFieldError),
                        onBlur: (event) => $setup.handleCountryBlur(event, setFieldValue),
                        onFocus: $setup.resetCountryOptions,
                        disabled: $props.isReadOnly
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedOptions(), (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["modelValue", "placeholder", "onChange", "onBlur", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 2
              }, 1024),
              ($setup.showProvince || $props.initialData?.Province)
                ? (_openBlock(), _createBlock($setup["Field"], {
                    key: 0,
                    name: "Province"
                  }, {
                    default: _withCtx(({ field, errorMessage }) => [
                      _createVNode(_component_el_form_item, {
                        class: "w-full label-custom",
                        error: errorMessage,
                        label: "Province"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, _mergeProps(field, {
                            modelValue: $setup.formData.Province,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formData.Province) = $event)),
                            class: "w-full custom-select",
                            placeholder: !$props.isReadOnly ? 'Select Province' : '',
                            filterable: "",
                            onInput: _cache[3] || (_cache[3] = (value) => $setup.filterMethodValueName(value.target?.value)),
                            onChange: _cache[4] || (_cache[4] = ($event: any) => ($setup.filterProvinceOptions(''))),
                            onBlur: (event) => $setup.handleProvinceBlur(event, setFieldValue),
                            onFocus: $setup.resetProvinceOptions,
                            disabled: $props.isReadOnly
                          }), {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.optionsProvince, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.Value,
                                  label: item.Name,
                                  value: item.Value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1040, ["modelValue", "placeholder", "onBlur", "disabled"])
                        ]),
                        _: 2
                      }, 1032, ["error"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 2),
            _createVNode($setup["Field"], { name: "CompanyRegistrationNumber" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Company Registration Number"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: 
                  !$props.isReadOnly ? 'Enter Company Registration Number' : ''
                
                    }, field, {
                      modelValue: $setup.formData.CompanyRegistrationNumber,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formData.CompanyRegistrationNumber) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "CompanyName" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Company Name",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Company Name' : ''
                    }, field, {
                      modelValue: $setup.formData.CompanyName,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formData.CompanyName) = $event)),
                      class: "custom-placeholder",
                      required: "",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "Address" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "label-custom",
                  error: errorMessage,
                  label: "Address"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: !$props.isReadOnly ? 'Enter Address' : ''
                    }, field, {
                      modelValue: $setup.formData.Address,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.formData.Address) = $event)),
                      class: "custom-placeholder",
                      disabled: $props.isReadOnly
                    }), null, 16, ["placeholder", "modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["Field"], { name: "PhoneCode" }, {
                default: _withCtx(({ field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom col-span-2",
                    error: errorMessage,
                    label: "Phone Code"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps(field, {
                        modelValue: $setup.formData.PhoneCode,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.formData.PhoneCode) = $event)),
                        class: "w-full custom-select",
                        placeholder: !$props.isReadOnly ? 'Select Phone Code' : '',
                        filterable: "",
                        onInput: _cache[9] || (_cache[9] = (value) => $setup.filterPhoneCode(value.target?.value)),
                        onChange: _cache[10] || (_cache[10] = ($event: any) => ($setup.filterPhoneCode(''))),
                        onBlur: (event) => $setup.handlePhoneCodeBlur(event, setFieldValue),
                        onFocus: $setup.resetPhoneCodeOptions,
                        disabled: $props.isReadOnly
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedPhoneCode(), (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["modelValue", "placeholder", "onBlur", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 2
              }, 1024),
              _createVNode($setup["Field"], { name: "ContactNumber" }, {
                default: _withCtx(({ field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom col-span-6",
                    error: errorMessage,
                    label: "Contact Number"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        placeholder: !$props.isReadOnly ? 'Enter Contact Number' : ''
                      }, field, {
                        modelValue: $setup.formData.ContactNumber,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.formData.ContactNumber) = $event)),
                        class: "custom-placeholder",
                        disabled: $props.isReadOnly
                      }), null, 16, ["placeholder", "modelValue", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}