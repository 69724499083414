import { defineComponent as _defineComponent } from 'vue'
import { IAccountInformation } from "@/models/Tenant";
import { configure, Field, Form } from "vee-validate";
import { ref, watch } from "vue";
import { object, string } from "yup";
import { debounce } from "lodash";
import TenantUserModule from "../TenantUser.module";
import { RESUITE_ROLE } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountInformation.Form',
  props: {
  isReadOnly: {
    type: Boolean,
    default: false,
  },
  initialData: {
    type: Object as () => IAccountInformation,
  },
},
  emits: ["update:accountData", "update:isAccountInfoValid"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const props = __props;
const isEmailDuplicate = ref(true);

const formData = ref<IAccountInformation>(
  props.initialData ??
    ({ ClientType: undefined, Email: "" } as IAccountInformation)
);

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const schema = object({
  ClientType: string().required().label("Client Type"),
  Email: string()
    .required()
    .email("Invalid email format.")
    .label("Account Email")
    .max(150, "Account Email must be at most 150 characters"),
});

const emailCache = new Map<
  string,
  { isDuplicate: boolean; errorMessage: string | null }
>();
const checkDuplicateEmail = async (setFieldError: any) => {
  if (props.isReadOnly) return;
  const email = formData.value.Email;
  if (!email) return;

  if (emailCache.has(email)) {
    const cached = emailCache.get(email);
    isEmailDuplicate.value = !cached?.isDuplicate;
    if (cached?.isDuplicate) {
      setFieldError("Email", cached.errorMessage);
    }
    emitFormValidity();
    return;
  }

  try {
    await TenantUserModule.checkDuplicateEmailAction(email);
    const isDuplicate = TenantUserModule.dataCheckDuplicateEmailGetter;
    const errorMessage = isDuplicate
      ? TenantUserModule.errorCheckDuplicateEmailGetter.message
      : null;
    emailCache.set(email, { isDuplicate, errorMessage });
    isEmailDuplicate.value = !isDuplicate;
    if (isDuplicate) {
      setFieldError("Email", errorMessage);
    }
    emitFormValidity();
  } catch (error) {
    console.error("Error checking email:", error);
  }
};

const debouncedCheckDuplicateEmail = debounce(checkDuplicateEmail, 200);

const emitFormValidity = () => {
  const isValid = schema.isValidSync(formData.value) && isEmailDuplicate.value;
  emit("update:isAccountInfoValid", isValid);
  emit("update:accountData", formData.value);
};

watch(
  formData,
  () => {
    emitFormValidity();
  },
  { deep: true }
);

watch(
  () => props.initialData,
  (newData) => {
    emailCache.clear();
    if (newData) {
      formData.value = { ...formData.value, ...newData };
    }
  },
  { immediate: true }
);

const __returned__ = { emit, props, isEmailDuplicate, formData, schema, emailCache, checkDuplicateEmail, debouncedCheckDuplicateEmail, emitFormValidity, get IAccountInformation() { return IAccountInformation }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, ref, watch, get object() { return object }, get string() { return string }, get debounce() { return debounce }, get TenantUserModule() { return TenantUserModule }, get RESUITE_ROLE() { return RESUITE_ROLE } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})